import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useColorModeValue,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import {
  FcAbout,
  FcAssistant,
  FcCollaboration,
  FcDonate,
  FcManager,
  FcHome,
} from 'react-icons/fc';

import { MdArrowBack } from 'react-icons/md';

const Projects = () => {
  return (
    <Box p={4} textAlign="left">
      <HStack justifySelf="flex-end">
        <Link to="/">
          <IconButton
            size="md"
            fontSize="lg"
            // aria-label={`Switch to ${text} mode`}
            variant="ghost"
            color="current"
            marginLeft="2"
            // onClick={toggleColorMode}
            icon={<MdArrowBack />}
          />
        </Link>
      </HStack>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          Projects
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
          Here are some of the things I've been working on.
        </Text>
      </Stack>

      <Container maxW={'5xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card maxW="25%">
            <CardHeader>
              <Icon as={FcAssistant} w={10} h={10} />
            </CardHeader>
            <CardBody py={0}>
              <Heading size="md">Tracking TerpTix</Heading>
              <Text mt={1} fontSize={'sm'}>
                Tool to track ticket sales for Maryland Basketball & Football.
              </Text>
            </CardBody>
            <CardFooter>
              <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                Learn more
              </Button>
            </CardFooter>
          </Card>
          <Card maxW="25%">
            <CardHeader>
              <Icon as={FcAssistant} w={10} h={10} />
            </CardHeader>
            <CardBody py={0}>
              <Heading size="md">Heading</Heading>
              <Text mt={1} fontSize={'sm'}>
                Lorem ipsum dolor sit amet catetur, adipisicing elit.
              </Text>
            </CardBody>
            <CardFooter>
              <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                Learn more
              </Button>
            </CardFooter>
          </Card>
          <Card maxW="25%">
            <CardHeader>
              <Icon as={FcAssistant} w={10} h={10} />
            </CardHeader>
            <CardBody py={0}>
              <Heading size="md">Heading</Heading>
              <Text mt={1} fontSize={'sm'}>
                Lorem ipsum dolor sit amet catetur, adipisicing elit.
              </Text>
            </CardBody>
            <CardFooter>
              <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                Learn more
              </Button>
            </CardFooter>
          </Card>
          <Card maxW="25%">
            <CardHeader>
              <Icon as={FcAssistant} w={10} h={10} />
            </CardHeader>
            <CardBody py={0}>
              <Heading size="md">Heading</Heading>
              <Text mt={1} fontSize={'sm'}>
                Lorem ipsum dolor sit amet catetur, adipisicing elit.
              </Text>
            </CardBody>
            <CardFooter>
              <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                Learn more
              </Button>
            </CardFooter>
          </Card>
          <Card maxW="25%">
            <CardHeader>
              <Icon as={FcAssistant} w={10} h={10} />
            </CardHeader>
            <CardBody py={0}>
              <Heading size="md">Heading</Heading>
              <Text mt={1} fontSize={'sm'}>
                Lorem ipsum dolor sit amet catetur, adipisicing elit.
              </Text>
            </CardBody>
            <CardFooter>
              <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                Learn more
              </Button>
            </CardFooter>
          </Card>
        </Flex>
      </Container>
    </Box>
  );
};

export default Projects;
