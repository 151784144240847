import React, { useState, useEffect } from 'react';

import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import {
  Container,
  Stack,
  HStack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Center,
  Image,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaTwitter, FaLinkedin, FaGithub, FaMailBulk } from 'react-icons/fa';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Marker,
} from 'react-simple-maps';

import { geoRobinson } from 'd3-geo-projection';

import { CheckIcon } from '@chakra-ui/icons';
import { MdEmail } from 'react-icons/md';

const geoUrl =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';

const markers = [
  {
    markerOffset: 30,
    name: "I'm Here",
    coordinates: [-76.9275, 38.996667],
  },
];

const HomeMap = () => {
  return (
    <ComposableMap
      projection="geoStereographic"
      projectionConfig={{
        rotate: [0, 0, 0],
        scale: 400,
        center: [-35, 25],
      }}
    >
      <Graticule stroke="#68d391" />
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#EAEAEC"
              stroke="#D6D6DA"
              style={{
                default: { outline: 'none' },
                hover: { outline: 'none' },
                pressed: { outline: 'none' },
              }}
            />
          ))
        }
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <circle r={10} fill="#68d391" stroke="#fff" strokeWidth={2} />
          {/* <text
            textAnchor="right"
            y={markerOffset}
            // style={{ fontFamily: 'system-ui', fill: '#5D5A6D' }}
            style={{
              fontWeight: 'bold',
              fontSize: '1em',
              fill: 'lightgreen',
              // stroke: '#fff',
              // strokeWidth: '2',
            }}
          >
            {name}
          </text> */}
        </Marker>
      ))}
    </ComposableMap>
  );
};

const Home = () => {
  return (
    <Container maxW={'7xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
          >
            <Text
              as={'span'}
              position={'relative'}
              //   _after={{
              //     content: "''",
              //     width: 'full',
              //     height: '5%',
              //     position: 'absolute',
              //     bottom: 1,
              //     left: 0,
              //     bg: 'green.300',
              //     zIndex: -1,
              //   }}
            >
              John Keniston
            </Text>
            <br />
            <Text fontSize="3xl" as={'span'} color={'green.300'}>
              Geospatial Developer
            </Text>
          </Heading>
          <Text color={'gray.500'}>
            I work with geospatial data & create applications.
          </Text>
          <Stack direction="row" align="center" justifyContent="center">
            <HStack>
              <Link
                to="https://www.linkedin.com/in/john-f-keniston"
                target="_blank"
              >
                <IconButton
                  rounded={'full'}
                  colorScheme="facebook"
                  icon={<FaLinkedin />}
                />
              </Link>
              <Link to="https://twitter.com/jkgeospatial" target="_blank">
                <IconButton
                  rounded={'full'}
                  colorScheme="twitter"
                  icon={<FaTwitter />}
                />
              </Link>
              <Link to="https://github.com/jkgeo" target="_blank">
                <IconButton
                  rounded={'full'}
                  colorScheme="gray"
                  icon={<FaGithub />}
                />
              </Link>
              <Link to="mailto:jfkeniston@gmail.com" target="_blank">
                <IconButton
                  rounded={'full'}
                  colorScheme="gray"
                  icon={<MdEmail />}
                />
              </Link>
              <ColorModeSwitcher />
            </HStack>
            {/* <Link to="projects">
              <span>
                <Button
                  rounded={'full'}
                  size={'lg'}
                  fontWeight={'normal'}
                  px={6}
                  colorScheme={'green'}
                  bg={'green.400'}
                  _hover={{ bg: 'green.500' }}
                >
                  Projects
                </Button>
              </span>
            </Link>
            <Link to="resume">
              <Button
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                //   leftIcon={<CheckIcon h={4} w={4} color={'gray.300'} />}
              >
                CV / Resume
              </Button>
            </Link> */}
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
        >
          <Box
            position={'relative'}
            height={'100%'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={'full'}
            overflow={'hidden'}
          >
            <HomeMap />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
};

export default Home;
