import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import {
  ChakraProvider,
  extendTheme,
  Box,
  Grid,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { FaMoon, FaSun, FaHome } from 'react-icons/fa';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import Projects from './components/Projects';
import Resume from './components/Resume';
import Home from './components/Home';

function App() {
  const theme = extendTheme({
    fonts: {
      body: 'Open Sans, sans-serif',
      heading: 'Open Sans, sans-serif',
      mono: 'Courier Prime, monospace',
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={1}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="projects/*" element={<Projects />} />
              <Route path="resume" element={<Resume />} />
            </Routes>
          </BrowserRouter>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
